/* Global styles */
:root {
    font-size: 18px;
}

#root {
    height: 100vh;
}

body {
    margin: 0;
    height: 100vh;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Styles related to the questionnaire */
.category-centered {
    text-align: center;
}

.primary-font {
    font-size: 1rem;
}

.secondary-font {
    font-size: 0.95rem;
}

.cardheader-font {
    font-size: 1.5rem;
}

.control-button {
    min-height: 100px;
    min-width: 170px;
    border-radius: 10px;
    margin: 5px;
}

.customed-small-btn{
    height: 50px;
    width: 150px;
    border-radius: 10px;
    margin: 3px;
}

@media (max-width: 400px) {
    .sm-container {
        display: flex;
        flex-direction: column;
        align-content: center;
    }
}

