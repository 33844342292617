.custom-button {
    border: 2px solid grey;
    border-right-color: black;
    border-left-color: black;
    border-radius: 10px;
    padding: .75rem;
}

.custom-button:active {
    border-color: black;
    border-right-color: gray;
    border-left-color: gray;
}

.small {
    min-height: 50px;
}

.medium {
    min-height: 100px;
    width: 150px;
}

.large {
    min-height: 150px;
}

.selected {
    border-color: black;
    border-width: 3px;
}

@media (max-width: 524px) {
    .medium {
        min-height: 110px;
        width: 110px;
    }

    .selected {
        border-color: black;
        border-width: 2px;
    }
}

@media (max-width: 400px) {
    .medium {
        min-height: 110px;
        width: 200px;
    }

    .selected {
        border-color: black;
        border-width: 3px;
    }
}

@media (max-width: 290px) {
    .medium {
        min-height: 100px;
        width: 120px;
    }

    .selected {
        border-color: black;
        border-width: 2px;
    }
}

